/**
 * Delays the rendering of the route until the keycloak instance is ready
 *
 * This middleware is applied to all routes by default
 */

export default defineNuxtRouteMiddleware(async (to, from) => {
    // const { $keycloakInitialization } = useNuxtApp();
    // await $keycloakInitialization();
})
